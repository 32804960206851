<template>
  <section class="mb-1">
    <p class="m-0 mt-2 font-weight-bold"> {{ title }} </p>
    <template v-if="delivery_comment.length > max">
      <section class="app-comment-content">
        <span> {{ expand ? delivery_comment : truncate(delivery_comment, max) }} </span>
      </section>
      <b-button
        v-bind="{
          variant: 'link'
        }"
        class="text-primary p-0"
        v-on="{
          click: onExpend
        }"
      >
        {{ expand ? 'Свернуть' : 'Подробнее'}}
      </b-button>
    </template>
    <template v-else>
      <section class="app-comment-content">
        <span> {{ delivery_comment ? delivery_comment : composition_comment }} </span>
      </section>
    </template>
  </section>
</template>

<script>
import truncate from 'truncate'

export default {
  name: 'CalendarOrderCardComment',
  props: {
    title: {
      type: String,
      default() {
        return 'Комментарий к заказу'
      },
    },
    delivery_comment: {
      type: String,
      default() {
        return ''
      },
    },
    composition_comment: {
      type: String,
      default() {
        return ''
      },
    },
    id: {
      type: Number,
      require: true,
    },
    max: {
      type: Number,
      default() {
        return 58
      },
    },
  },
  data() {
    return {
      expand: false,
    }
  },
  methods: {
    truncate,
    onExpend(event) {
      event.stopPropagation()

      this.expand = !this.expand
    },
  },
}
</script>

<style lang="scss" scoped>
.app-comment-content {
    height: 40px;
    box-sizing: border-box;
    font-size: 0.9rem;
    overflow: hidden;
    margin-bottom: 10px;
    transition: all 0.25s ease;
}
</style>
